import {initializeApp} from 'firebase/app';
import {getAuth} from "firebase/auth";

import firebase from 'firebase/compat/app';
import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'
import Cookies from 'js-cookie';
import * as request from 'request-promise';

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyDro8h_VTGywvBINYblC-x3Bky6pxRVXpQ",
  authDomain: "test-cbe58.firebaseapp.com",
  projectId: "test-cbe58",
  storageBucket: "test-cbe58.appspot.com",
  messagingSenderId: "629021213036",
  appId: "1:629021213036:web:6a55420bfb974b39b2f349",
  measurementId: "G-D8LYS30GP0"
};

const app = initializeApp(firebaseConfig);

let firebaseAuth = getAuth(app);

// Initialize the FirebaseUI Widget using Firebase.
let ui = new firebaseui.auth.AuthUI(firebaseAuth);

firebaseAuth.onAuthStateChanged(function(user) {
  document.getElementById('loading').style.display = 'none';
  document.getElementById('loaded').style.display = 'block';
  user ? handleSignedInUser(user) : handleSignedOutUser();
});

let user = firebaseAuth.currentUser;
// firebaseAuth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);

if (!user) {
  ui.start('#firebaseui-auth-container', {
    signInOptions: [
      firebase.auth.EmailAuthProvider.PROVIDER_ID
    ],
    callbacks: {
      signInSuccessWithAuthResult: function (currentUser, credential, redirectUrl) {
        Cookies.set('auth', JSON.stringify(currentUser), {domain: getCanonicalHost(window.location.hostname)});
        return false;
      }
    }
  });
}

function getCanonicalHost(hostname) {
  const MAX_TLD_LENGTH = 3;

  function isNotTLD(_) { return _.length > MAX_TLD_LENGTH; };

  hostname = hostname.split('.');
  hostname = hostname.slice(Math.max(0, hostname.findLastIndex(isNotTLD)));
  hostname = hostname.join('.');

  return hostname;
}

let handleSignedInUser = function(user) {
  console.log(user.stsTokenManager);
  Cookies.set('auth', JSON.stringify(user.toJSON()), {domain: getCanonicalHost(window.location.hostname)});

  document.getElementById('user-signed-in').style.display = 'block';
  document.getElementById('user-signed-out').style.display = 'none';
  document.getElementById('name').textContent = user.displayName;
  document.getElementById('email').textContent = user.email;
  document.getElementById('phone').textContent = user.phoneNumber;
  document.getElementById('photo').style.display = 'none';
};

function getCustomToken(idToken) {
  return request.get({
    url: `https://us-central1-${firebaseConfig['projectId']}.cloudfunctions.net/create_custom_token?id_token=${idToken}`,
    json: true,
  });
}

function loginWithIdToken(idToken) {
  if (idToken) {
    return getCustomToken(idToken).then((token) => {
      // console.log(`Retrieved custom token: ${custom_token}`);
      return firebase.auth().signInWithCustomToken(token);
    });
  }
}

let handleSignedOutUser = function() {
  let auth = Cookies.get('auth');
  let user = null;
  if(auth){
    console.log(auth);
    user = JSON.parse(auth);
    console.log(user);
    if(user){
      loginWithIdToken(user.stsTokenManager.accessToken);
    }
  } else {
    document.getElementById('user-signed-out').style.display = 'block';
  }
}

let initApp = function(){
  alert('hello');
  document.getElementById('logout').onclick = function() {
    // Cookies.delete('auth');
    firebaseAuth.signOut();
    Cookies.remove('auth', {domain: getCanonicalHost(window.location.hostname)});
  };
}

window.addEventListener('load', initApp);
